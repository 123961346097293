import { i18n } from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TareaService from 'src/modules/tarea/tareaService';
import Message from 'src/view/shared/message';

import moment from 'moment-timezone';
import tareaListActions from '../list/tareaListActions';

const prefix = 'TAREA_FORM';

const tareaFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  //SET_RECORD: `${prefix}_SET_RECORD`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: tareaFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await TareaService.find(id);
      }

      dispatch({
        type: tareaFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tareaFormActions.INIT_ERROR,
      });

      getHistory().push('/tarea');
    }
  },

  doCreate: (values, redirect = true) => async (dispatch) => {
    try {
      dispatch({
        type: tareaFormActions.CREATE_STARTED,
      });

      await TareaService.create(values);

      dispatch({
        type: tareaFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.tarea.create.success'));

      //console.log('%c⧭ values.silent', 'color: #ffa280', values.silent);
      if (!values.silent && redirect) {
        getHistory().push('/tarea');
      }
      dispatch(
        layoutActions.doVisibleDrawer({
          drawerId: values.drawerId || 'DrawerTareaListPage',
          drawerVisible: false,
          drawerChanged: moment().unix(),
          drawerUpdateComponent: values.drawerUpdateComponent || 'TareaListPage',
        }),
      );
      return true;
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: tareaFormActions.CREATE_ERROR,
      });
      return false;
    }
  },

  doUpdate:
    (id, values, edited: any = null) =>
      async (dispatch, getState) => {
        try {
          dispatch({
            type: tareaFormActions.UPDATE_STARTED,
          });

          await TareaService.update(id, values);

          if (edited) edited(id, values);

          dispatch({
            type: tareaFormActions.UPDATE_SUCCESS,
          });
          if (id) {
            dispatch({
              type: tareaListActions.PATCH_RECORD,
              payload: { id, values },
            });
          }

          if (!values.silent) {
            Message.success(i18n('entities.tarea.update.success'));
          }

          // getHistory().goBack();

          if (!values.updating) {
            // console.log('%c⧭ !values.updating', 'color: #ffcc00', 'JELP');
            dispatch(
              layoutActions.doVisibleDrawer({
                drawerId: 'DrawerTareaFormPage',
                drawerVisible: false,
                drawerChanged: moment().unix(),
                drawerUpdateComponent: 'PendienteListPage',
              }),
            );
          }
          return true;
        } catch (error) {
          Errors.handle(error);
          dispatch({
            type: tareaFormActions.UPDATE_ERROR,
          });
          return false;
        }
      },
  setRecord: (values) => async (dispatch) => {
    try {
      dispatch({
        type: tareaFormActions.INIT_SUCCESS,
        payload: values,
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: tareaFormActions.INIT_ERROR,
      });
    }
  },
};

export default tareaFormActions;
