import {
  AccessTime,
  AddBox,
  AllInbox,
  AssignmentInd,
  Business,
  Check,
  Engineering,
  FindInPage,
  Group,
  LibraryAddCheck,
  LinearScale,
  LocalAtm,
  LockClock,
  ViewList,
  WatchLater
} from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BrushIcon from '@mui/icons-material/Brush';
import BuildIcon from '@mui/icons-material/Build';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaletteIcon from '@mui/icons-material/Palette';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import StorageIcon from '@mui/icons-material/Storage';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TitleIcon from '@mui/icons-material/Title';
import config from 'src/config';
import { i18n } from 'src/i18n';
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;
// const sw_user: any = JSON.parse(localStorage.getItem('sw_user') || '{}');
// const dev_email = ['ckroeger@stemwaste.cl', 'ccampos@stemwaste.cl'];
// const dev_access = dev_email.includes(sw_user?.email);

export default [
  {
    path: '/',
    exact: true,
    icon: DashboardIcon,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },
  {
    path: '/business-intelligence/dashboard',
    permissionRequired: permissions.dashboardRead,
    icon: ShowChartIcon,
    label: i18n('dashboard.analytics'),
  },
  // {
  //   path: '/kanban',
  //   permissionRequired: permissions.kanbanRead,
  //   icon: DashboardCustomizeTwoToneIcon,
  //   label: i18n('entities.tarea.menuKanban'),
  // },
  // {
  //   path: '/scheduler',
  //   permissionRequired: permissions.schedulerRead,
  //   icon: EventNoteTwoToneIcon,
  //   label: i18n('entities.tarea.menuScheduler'),
  // },
  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planRead,
    icon: CreditCardOutlinedIcon,
    label: i18n('plan.menu'),
  },

  // {
  //   path: '/user',
  //   label: i18n('user.menu'),
  //   permissionRequired: permissions.userRead,
  //   icon: PersonIcon,
  // },

  // {
  //   path: '/audit-logs',
  //   icon: HistoryIcon,
  //   label: i18n('auditLog.menu'),
  //   permissionRequired: permissions.auditLogRead,
  // },
  {
    path: '/pendiente',
    permissionRequired: permissions.pendienteRead,
    icon: PlaylistAddCheckIcon,
    label: i18n('entities.pendiente.menu'),
  },
  {
    path: '/planificacion',
    permissionRequired: permissions.planificacionRead,
    icon: ViewList,
    label: i18n('entities.planificacion.menu'),
  },
  {
    path: '/tarea',
    permissionRequired: permissions.tareaRead,
    icon: LibraryAddCheck,
    label: i18n('entities.tarea.menu'),
  },
  {
    path: '/contractors',
    permissionRequired: permissions.incidentRead,
    icon: Engineering,
    label: i18n('entities.incident.contractors.label'),
  },
  {
    path: '/ausencia',
    permissionRequired: permissions.absenceRead,
    icon: PersonOutlineIcon,
    label: i18n('entities.absence.menu'),
  },
  {
    path: '/incidente',
    permissionRequired: permissions.incidentRead,
    icon: ReportProblemIcon,
    label: i18n('entities.incident.menu'),
  },
  //**// {
  //   path: '/registro',
  //   permissionRequired: permissions.registroRead,
  //   icon: Note,
  //   label: i18n('entities.registro.menu'),
  // },**/
  // {
  //   path: '/ubicacion',
  //   permissionRequired: permissions.ubicacionRead,
  //   icon: EditLocation,
  //   label: i18n('entities.ubicacion.menu'),
  // },
  // {
  //   path: '/activo',
  //   permissionRequired: permissions.activoRead,
  //   icon: Dns,
  //   label: i18n('entities.activo.menu'),
  // },
  // {
  //   path: '/trazabilidad',
  //   permissionRequired: permissions.trazabilidadRead,
  //   icon: LinearScale,
  //   label: i18n('entities.trazabilidad.menu'),
  // },
  // TODO: reordenar
  // {
  //   path: '/notificacion',
  //   permissionRequired: permissions.notificacionRead,
  //   icon: NotificationsActiveSharpIcon,
  //   label: i18n('entities.notificacion.menu'),
  // },
  {
    path: '/firma',
    permissionRequired: permissions.firmaRead,
    icon: Check,
    label: i18n('entities.firma.menu'),
  },
  // {
  //   path: '/categoria',
  //   permissionRequired: permissions.categoriaRead,
  //   icon: ViewModuleIcon,
  //   label: i18n('entities.categoria.menu'),
  // },
  // {
  //   path: '/habilidad',
  //   permissionRequired: permissions.habilidadRead,
  //   icon: EmojiSymbols,
  //   label: i18n('entities.habilidad.menu'),
  // },
  {
    path: '/gestor-documental',
    permissionRequired: permissions.gestorDocumentalRead,
    icon: FindInPage,
    label: i18n('entities.gestorDocumental.menu'),
  },

  {
    label: i18n('entities.masterMenus.multiWay'),
    subMenu: [
      {
        path: '/comunicacion-interna',
        permissionRequired: permissions.comunicacionInternaRead,
        icon: QuestionAnswerIcon,
        label: i18n('entities.comunicacionInterna.menu'),
      },
      {
        path: '/tema-comunicacion',
        permissionRequired: permissions.temaComunicacionRead,
        icon: TitleIcon,
        label: i18n('entities.temaComunicacion.menu'),
      },
    ],
  },
  // {
  //   label: i18n('entities.masterMenus.catalogoOperativo'),
  //   subMenu: [
  //     {
  //       path: '/sucursal',
  //       permissionRequired: permissions.sucursalRead,
  //       icon: Business,
  //       label: i18n('entities.sucursal.menu'),
  //     },

  //     /*
  //     {
  //       path: '/factura',
  //       permissionRequired: permissions.facturaRead,
  //       icon: ReceiptIcon,
  //       label: i18n('entities.factura.menu'),
  //     },
  //     */

  //     {
  //       path: '/pais',
  //       permissionRequired: permissions.paisRead,
  //       icon: LanguageIcon,
  //       label: i18n('entities.pais.menu'),
  //     },
  //     {
  //       path: '/division',
  //       permissionRequired: permissions.divisionRead,
  //       icon: ClassIcon,
  //       label: i18n('entities.division.menu'),
  //     },
  //     {
  //       path: '/segmentacion',
  //       permissionRequired: permissions.segmentacionRead,
  //       icon: ClassIcon,
  //       label: i18n('entities.segmentacion.menu'),
  //     },
  //     {
  //       path: '/rubro',
  //       permissionRequired: permissions.rubroRead,
  //       icon: LocationCityIcon,
  //       label: i18n('entities.rubro.menu'),
  //     },
  //     {
  //       path: '/producto',
  //       permissionRequired: permissions.productoRead,
  //       icon: LoyaltyIcon,
  //       label: i18n('entities.producto.menu'),
  //     },
  //     {
  //       path: '/categoria-producto',
  //       permissionRequired:
  //         permissions.categoriaProductoRead,
  //       icon: MoreIcon,
  //       label: i18n('entities.categoriaProducto.menu'),
  //     },
  //   ],
  // },
  // {
  //   label: i18n(
  //     'entities.masterMenus.inteligenciaNegocios',
  //   ),
  //   icon: Assessment,
  //   subMenu: [

  //   ],
  // },
  {
    label: i18n('entities.masterMenus.catalogo'),
    icon: LinearScale,
    subMenu: [
      // {
      //   path: '/empresa',
      //   permissionRequired: permissions.empresaRead,
      //   icon: Business,
      //   label: i18n('entities.empresa.menu'),
      // },
      // {
      //   path: '/servicio-proveedor',
      //   permissionRequired:
      //     permissions.servicioProveedorRead,
      //   icon: GroupAdd,
      //   label: i18n('entities.servicioProveedor.menu'),
      // },
      {
        path: '/tercero',
        permissionRequired: permissions.terceroRead,
        icon: Business,
        label: i18n('entities.tercero.menu'),
      },
      // TODO: reordenar
      // {
      //   path: '/servicio',
      //   permissionRequired: permissions.servicioRead,
      //   icon: HomeRepairService,
      //   label: i18n('entities.servicio.menu'),
      // },
      // {
      //   path: '/contacto',
      //   permissionRequired: permissions.contactoRead,
      //   icon: Contacts,
      //   label: i18n('entities.contacto.menu'),
      // },
      // {
      //   path: '/centro-costo',
      //   permissionRequired: permissions.centroCostoRead,
      //   icon: AccountBalance,
      //   label: i18n('entities.centroCosto.menu'),
      // },
      // {
      //   path: '/presupuesto',
      //   permissionRequired: permissions.presupuestoRead,
      //   icon: AttachMoney,
      //   label: i18n('entities.presupuesto.menu'),
      // },
      // {
      //   path: '/cuenta-contable',
      //   permissionRequired: permissions.cuentaContableRead,
      //   icon: AccountBalanceWallet,
      //   label: i18n('entities.cuentaContable.menu'),
      // },
      // {
      //   path: '/tipo-centro-costo',
      //   permissionRequired: permissions.tipoCentroCostoRead,
      //   icon: AddBox,
      //   label: i18n('entities.tipoCentroCosto.menu'),
      // },
      // {
      //   path: '/tipo-presupuesto',
      //   permissionRequired: permissions.tipoPresupuestoRead,
      //   icon: AddBox,
      //   label: i18n('entities.tipoPresupuesto.menu'),
      // },
      // {
      //   path: '/tipo-cuenta-contable',
      //   permissionRequired: permissions.tipoCuentaContableRead,
      //   icon: AddBox,
      //   label: i18n('entities.tipoCuentaContable.menu'),
      // },
      // {
      //   path: '/tipo-servicio',
      //   permissionRequired: permissions.tipoServicioRead,
      //   icon: AddBox,
      //   label: i18n('entities.tipoServicio.menu'),
      // },
      {
        path: '/clasificacion-tercero',
        permissionRequired: permissions.clasificacionTerceroRead,
        icon: AddBox,
        label: i18n('entities.clasificacionTercero.menu'),
      },
      // {
      //   path: '/unidad-medida',
      //   permissionRequired: permissions.unidadMedidaRead,
      //   icon: AddBox,
      //   label: i18n('entities.unidadMedida.menu'),
      // },
      // {
      //   path: '/tipo-unidad',
      //   permissionRequired: permissions.tipoUnidadRead,
      //   icon: AddBox,
      //   label: i18n('entities.tipoUnidad.menu'),
      // },
      {
        path: '/tipo-activo',
        permissionRequired: permissions.tipoActivoRead,
        icon: AddBox,
        label: i18n('entities.tipoActivo.menu'),
      },
      // {
      //   path: '/material',
      //   permissionRequired: permissions.materialRead,
      //   icon: AddBox,
      //   label: i18n('entities.material.menu'),
      // },
      {
        path: '/desc-registro',
        permissionRequired: permissions.descRegistroRead,
        icon: AddBox,
        label: i18n('entities.descRegistro.menu'),
      },
      // {
      //   path: '/marca',
      //   permissionRequired: permissions.marcaRead,
      //   icon: AcUnit,
      //   label: i18n('entities.marca.menu'),
      // },
      // {
      //   path: '/modelo',
      //   permissionRequired: permissions.modeloRead,
      //   icon: Commute,
      //   label: i18n('entities.modelo.menu'),
      // },
      // {
      //   path: '/cantidad-material-activo',
      //   permissionRequired:
      //     permissions.cantidadMaterialActivoRead,
      //   icon: TableChart,
      //   label: i18n('entities.cantidadMaterialActivo.menu'),
      // },
      // {
      //   path: '/tabla-costo-energia',
      //   permissionRequired:
      //     permissions.tablaCostoEnergiaRead,
      //   icon: BatteryChargingFull,
      //   label: i18n('entities.tablaCostoEnergia.menu'),
      // },
      // {
      //   path: '/tipo-energia',
      //   permissionRequired: permissions.tipoEnergiaRead,
      //   icon: MergeType,
      //   label: i18n('entities.tipoEnergia.menu'),
      // },
      // {
      //   path: '/campo-personalizado',
      //   permissionRequired:
      //     permissions.campoPersonalizadoRead,
      //   icon: EditAttributes,
      //   label: i18n('entities.campoPersonalizado.menu'),
      // },
      // {
      //   path: '/estado-activos',
      //   permissionRequired: permissions.estadoActivosRead,
      //   icon: Room,
      //   label: i18n('entities.estadoActivos.menu'),
      // },
    ],
  },

  {
    label: i18n('entities.masterMenus.configuraciones'),
    subMenu: [
      {
        path: '/settings',
        permissionRequired: permissions.settingsEdit,
        icon: PaletteIcon,
        label: i18n('settings.menu'),
      },
      {
        path: '/nivel-prioridad',
        permissionRequired: permissions.nivelPrioridadRead,
        icon: PriorityHighIcon,
        label: i18n('entities.nivelPrioridad.menu'),
      },
      // {
      //   path: '/configuracion-notificacion',
      //   permissionRequired: permissions.configuracionNotificacionRead,
      //   icon: SettingsSharpIcon,
      //   label: i18n('entities.configuracionNotificacion.menu'),
      // },
      // {
      //   path: '/etiqueta',
      //   permissionRequired: permissions.etiquetaRead,
      //   icon: Label,
      //   label: i18n('entities.etiqueta.menu'),
      // },
    ],
  },

  {
    label: i18n('entities.masterMenus.tareas'),
    subMenu: [
      // {
      //   path: '/tarea',
      //   permissionRequired: permissions.tareaRead,
      //   icon: LibraryAddCheck,
      //   label: i18n('entities.tarea.menu'),
      // },
      // {
      //   path: '/gantt',
      //   permissionRequired: permissions.ganttRead,
      //   icon: Assessment,
      //   label: i18n('entities.tarea.menuGantt'),
      // },
      {
        path: '/recurso',
        permissionRequired: permissions.recursoRead,
        icon: LocalAtm,
        label: i18n('entities.recurso.menu'),
      },
      {
        path: '/planificador-tarea',
        permissionRequired: permissions.planificadorTareaRead,
        icon: AccessTime,
        label: i18n('entities.planificadorTarea.menu'),
      },
      // {
      //   path: '/planificacion',
      //   permissionRequired: permissions.planificacionRead,
      //   icon: ViewList,
      //   label: i18n('entities.planificacion.menu'),
      // },
      {
        path: '/modelo-tarea',
        permissionRequired: permissions.modeloTareaRead,
        icon: BrushIcon,
        label: i18n('entities.modeloTarea.menu'),
      },
      {
        path: '/sub-tarea',
        permissionRequired: permissions.subTareaRead,
        icon: ListAltIcon,
        label: i18n('entities.subTarea.menu'),
      },
      {
        path: '/objetivo',
        permissionRequired: permissions.objetivoRead,
        icon: ControlPointIcon,
        label: i18n('entities.objetivo.menu'),
      },
      // {
      //   path: '/codificacion-i-s-o',
      //   permissionRequired: permissions.codificacionISORead,
      //   icon: CardMembership,
      //   label: i18n('entities.codificacionISO.menu'),
      // },
    ],
  },

  {
    label: i18n('entities.masterMenus.procedimientos'),
    subMenu: [
      /*
      {
        path: '/categoria-formulario',
        permissionRequired: permissions.categoriaFormularioRead,
        icon: CategoryIcon,
        label: i18n('entities.categoriaFormulario.menu'),
      },
      */
      {
        path: '/modelo-formulario',
        permissionRequired: permissions.modeloFormularioRead,
        icon: BrushIcon,
        label: i18n('entities.modeloFormulario.menu'),
      },
      // {
      //   path: '/pregunta',
      //   permissionRequired: permissions.preguntaRead,
      //   icon: HelpIcon,
      //   label: i18n('entities.pregunta.menu'),
      // },
      // {
      //   path: '/respuesta',
      //   permissionRequired: permissions.respuestaRead,
      //   icon: InsertCommentIcon,
      //   label: i18n('entities.respuesta.menu'),
      // },
    ],
  },

  {
    label: i18n('entities.masterMenus.inventario'),
    subMenu: [
      {
        path: '/inventario',
        permissionRequired: permissions.inventarioRead,
        icon: AllInbox,
        label: i18n('entities.inventario.menu'),
      },
      {
        path: '/historial-inventario',
        permissionRequired: permissions.historialInventarioRead,
        icon: LockClock,
        label: i18n('entities.historialInventario.menu'),
      },
    ],
  },

  {
    label: i18n('entities.masterMenus.recursosHumanos'),
    subMenu: [
      {
        path: '/asignador-funcion',
        permissionRequired: permissions.asignadorFuncionRead,
        icon: Group,
        label: i18n('entities.asignadorFuncion.menu'),
      },
      {
        path: '/perfil-empleado',
        permissionRequired: permissions.perfilEmpleadoRead,
        icon: AssignmentInd,
        label: i18n('entities.perfilEmpleado.menu'),
      },
      // {
      //   path: '/dia-festivo',
      //   permissionRequired: permissions.diaFestivoRead,
      //   icon: EmojiEmotions,
      //   label: i18n('entities.diaFestivo.menu'),
      // },
      {
        path: '/puesto',
        permissionRequired: permissions.puestoRead,
        icon: SupervisedUserCircleIcon,
        label: i18n('entities.puesto.menu'),
      },
      {
        path: '/nivel-puesto',
        permissionRequired: permissions.nivelPuestoRead,
        icon: AccountTreeIcon,
        label: i18n('entities.nivelPuesto.menu'),
      },
      {
        path: '/departamento',
        permissionRequired: permissions.departamentoRead,
        icon: DonutSmallIcon,
        label: i18n('entities.departamento.menu'),
      },
      {
        path: '/turno-laboral',
        permissionRequired: permissions.turnoLaboralRead,
        icon: WatchLater,
        label: i18n('entities.turnoLaboral.menu'),
      },
      {
        path: '/funcion',
        permissionRequired: permissions.funcionRead,
        icon: BuildIcon,
        label: i18n('entities.funcion.menu'),
      },
    ],
  },

  // {
  //   label: i18n('entities.masterMenus.eventos'),
  //   subMenu: [
  //     {
  //       path: '/evento',
  //       permissionRequired: permissions.eventoRead,
  //       icon: EventIcon,
  //       label: i18n('entities.evento.menu'),
  //     },
  //     {
  //       path: '/recordatorio',
  //       permissionRequired: permissions.recordatorioRead,
  //       icon: Alarm,
  //       label: i18n('entities.recordatorio.menu'),
  //     },
  //   ],
  // },

  // {
  //   label: i18n('entities.masterMenus.solicitudes'),
  //   icon: Delete,
  //   subMenu: [
  //     {
  //       path: '/solicitud-reciclaje',
  //       permissionRequired: permissions.solicitudReciclajeRead,
  //       icon: MailOutline,
  //       label: i18n('entities.solicitudReciclaje.menu'),
  //     },
  //     {
  //       path: '/solicitud',
  //       permissionRequired: permissions.solicitudRead,
  //       icon: RequestPage,
  //       label: i18n('entities.solicitud.menu'),
  //     },
  //     {
  //       path: '/solicitud-activo',
  //       permissionRequired: permissions.solicitudActivoRead,
  //       icon: TableRows,
  //       label: i18n('entities.solicitudActivo.menu'),
  //     },
  //   ],
  // },
  {
    path: '/data',
    permissionRequired: permissions.dataRead,
    icon: StorageIcon,
    label: i18n('entities.data.menu'),
  },
  {
    path: '/data-lake',
    permissionRequired: permissions.dataLakeRead,
    icon: DonutSmallIcon,
    label: i18n('entities.dataLake.menu'),
  },
].filter(Boolean);
