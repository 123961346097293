import moment from 'moment-timezone';
import {i18n} from 'src/i18n';
import layoutActions from 'src/modules/layout/layoutActions';
import PerfilEmpleadoListActions from 'src/modules/perfilEmpleado/list/perfilEmpleadoListActions';
import PerfilEmpleadoService from 'src/modules/perfilEmpleado/perfilEmpleadoService';
import Errors from 'src/modules/shared/error/errors';
import {getHistory} from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'PERFILEMPLEADO_FORM';

const perfilEmpleadoFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: perfilEmpleadoFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await PerfilEmpleadoService.find(id);
      }

      dispatch({
        type: perfilEmpleadoFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: perfilEmpleadoFormActions.INIT_ERROR,
      });

      getHistory().push('/perfil-empleado');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: perfilEmpleadoFormActions.CREATE_STARTED,
      });

      await PerfilEmpleadoService.create(values);

      dispatch({
        type: perfilEmpleadoFormActions.CREATE_SUCCESS,
      });

      Message.success(i18n('entities.perfilEmpleado.create.success'));

      // getHistory().push('/perfil-empleado');

      dispatch(PerfilEmpleadoListActions.doFetchCurrentFilter());

      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerPerfilEmpleadoFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: null,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: perfilEmpleadoFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: perfilEmpleadoFormActions.UPDATE_STARTED,
      });

      await PerfilEmpleadoService.update(id, values);

      dispatch({
        type: perfilEmpleadoFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n('entities.perfilEmpleado.update.success'));

      // getHistory().push('/perfil-empleado');

      dispatch(PerfilEmpleadoListActions.doFetchCurrentFilter());

      layoutActions.doVisibleDrawer({
        drawerId: 'DrawerPerfilEmpleadoFormPage',
        drawerVisible: false,
        drawerChanged: moment().unix(),
        drawerUpdateComponent: null,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: perfilEmpleadoFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default perfilEmpleadoFormActions;
