import chunk from 'lodash/chunk';
import md5 from 'md5';
import {i18n} from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Importer from 'src/modules/shared/importer/importer';
import yupImporterSchemas from 'src/modules/shared/yup/yupImporterSchemas';
import dataListSelectors from '../list/dataListSelectors';

async function importRow(dispatch, actions, importer, importFn, data, selectedEntity) {
  try {
    const importableData: any = [];
    for (const row of data) {
      try {
        // clean copy of row
        const cleanRow = JSON.parse(JSON.stringify(row));
        delete cleanRow._line;
        delete cleanRow._status;
        importableData.push(cleanRow); //importer.castForDisplay(row));
      } catch (e) {
        console.log('importableData', e);
      }
    }
    const importHash = md5(JSON.stringify(data[0]));
    await importFn(selectedEntity, importableData, importHash);

    data.forEach((row) =>
      dispatch({
        type: actions.IMPORT_BATCH_SUCCESS,
        payload: {
          line: row._line,
        },
      }),
    );
  } catch (error) {
    data.forEach((row) =>
      dispatch({
        type: actions.IMPORT_BATCH_ERROR,
        payload: {
          line: row._line,
          errorMessage: Errors.selectMessage(error),
        },
      }),
    );
  }
}

const buildDynamicFields = (entityStructure) => {
  const result = Object.keys(entityStructure)
    .filter((key) => key !== 'dateEnd')
    .map((key) => {
      const type = entityStructure[key].type;
      const schema = yupImporterSchemas[type] || yupImporterSchemas.string;
      return {
        name: key,
        label: key, //entityStructure[key].label || key,
        schema: schema(
          key, //entityStructure[key].label || key
          {required: entityStructure[key].required || false},
        ),
      };
    });
  return result;
};
const buildDynamicFieldsC = () => {
  const result = [
    'Nombres',
    'Apellidos',
    'Rut_Trabajador',
    'Fecha_Nacimiento',
    'Genero',
    'Nacionalidad',
    'Mandante',
    'Rut_Contratista',
    'Empresa_Contratista',
    'Tipo_Contrato',
    'Fecha_Inicio',
    'Fecha_Finiquito',
    'Fecha_Modificacion',
    // 'empresa',
    // 'Gerencia',
    // 'rut_contratista',
    // 'nombre_contratista',
    // 'rut_trabajador',
    // 'nombres',
    // 'apellidos',
    // 'cargo',
    // 'desde',
    // 'hasta',
    // 'dias_perdidos',
    // 'tipo',
    // 'referente_al_ingreso',
    // 'grado_de_accidente',
    // 'instalacion',
    // 'origen',
    // 'descripcion_accidente',
  ].map((key) => {
    let type = 'string';
    if (key === 'Fecha_Modificacion') {
      type = 'datetime2';
    }
    if (key === 'Fecha_Nacimiento' || key === 'Fecha_Inicio' || key === 'Fecha_Finiquito') {
      type = 'excelDate';
    }
    if (key === 'desde' || key === 'hasta') {
      type = 'excelDate';
    }
    // if(key === 'Rut_Contratista'){}
    const schema = yupImporterSchemas[type] || yupImporterSchemas.string;
    return {
      name: key,
      label: key, //entityStructure[key].label || key,
      schema: schema(
        key, //entityStructure[key].label || key
        {required: false},
      ),
    };
  });
  return result;
};
const newImporter = (entityStructure, selectedEntity) => {
  const entityFields = selectedEntity === 'contractor' ? buildDynamicFieldsC() : buildDynamicFields(entityStructure);
  return new Importer(entityFields);
};
const actions = (prefix, selectors, importFn, importFields, templateFileName, batchSize = 10, options = null) => {
  const actions = {
    RESETED: `${prefix}_RESETED`,

    FILE_READ_ERROR: `${prefix}_FILE_READ_ERROR`,
    FILE_READ_SUCCESS: `${prefix}_FILE_READ_SUCCESS`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
    IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,
    IMPORT_PAUSED: `${prefix}_IMPORT_PAUSED`,
    IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,

    IMPORT_BATCH_ERROR: `${prefix}_IMPORT_BATCH_ERROR`,
    IMPORT_BATCH_SUCCESS: `${prefix}_IMPORT_BATCH_SUCCESS`,
    IMPORT_MULTI_SUCCESS: `${prefix}_IMPORT_MULTI_SUCCESS`,

    doChangePagination: (pagination) => ({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    }),

    doChangeSort: (rows, sorter) => async (dispatch, getState) => {
      const {field, order} = sorter;

      let sortFn = (a, b) => (String(a[field]) || '').localeCompare(String(b[field]) || '');

      if (field === '_line') {
        sortFn = (a, b) => a._line - b._line;
      }

      if (field === '_status') {
        sortFn = (a, b) => (a._status || '').localeCompare(b._status || '');
      }

      let sortedRows = [...rows].sort(sortFn);

      if (order === 'desc') {
        sortedRows = sortedRows.reverse();
      }

      dispatch({
        type: actions.SORTER_CHANGED,
        payload: {
          sortedRows,
          sorter,
        },
      });
    },

    doReset: () => {
      return {
        type: actions.RESETED,
      };
    },

    doPause: () => {
      return {
        type: actions.IMPORT_PAUSED,
      };
    },

    doImport: () => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.IMPORT_STARTED,
        });

        const selectedEntity = dataListSelectors.selectEntity(getState());
        const entityStructure = dataListSelectors.selectEntityStructure(getState());

        const pendingRows = selectors.selectPendingRows(getState());

        const importer = newImporter(entityStructure, selectedEntity);

        const pendingBatches = chunk(pendingRows, batchSize); //.slice(0, 1000)

        const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));
        for (let batch of pendingBatches as any) {
          const paused = !selectors.selectImporting(getState());

          if (paused) {
            return;
          }

          //console.log('batch', batch);
          if (selectedEntity === 'contractor') {
            console.log('batch[0]._line', batch[0]._line, batch[0]);
            for (let row of batch as any) {
              row.dataId = row.Rut_Contratista + '_' + row.Fecha_Inicio;
            }
          }

          await importRow(dispatch, actions, importer, importFn, batch, selectedEntity);
          await sleep(1000);
        }

        dispatch({
          type: actions.IMPORT_SUCCESS,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.IMPORT_ERROR,
        });
      }
    },

    doDownloadTemplate: () => async (dispatch, getState) => {
      if (options) {
        //console.log('%c⧭ doDownloadTemplate options', 'color: #731d1d', templateFileName, options);
      }
      const entityStructure = dataListSelectors.selectEntityStructure(getState());
      const selectedEntity = dataListSelectors.selectEntity(getState());
      const importer = newImporter(entityStructure, selectedEntity);
      await importer.downloadTemplate(templateFileName, options);
    },

    doReadFile:
      (file, config: any = {}) =>
      async (dispatch, getState) => {
        try {
          const entityStructure = dataListSelectors.selectEntityStructure(getState());
          const importer = newImporter(entityStructure, dataListSelectors.selectEntity(getState()));

          let rawData = await importer.convertExcelFileToJson(file, {...config, skipHeader: false});

          if (!rawData || !rawData.length) {
            throw new Error(i18n('importer.errors.invalidFileEmpty'));
          }

          rawData = await importer.castWithHeaderColumns(rawData);
          //rawData = await importer.castWithHeaderColumns(rawData.slice(0, 1).concat(rawData.slice(1499)));

          console.log('%c⧭ rawData', 'color: #aa00ff', rawData);

          dispatch({
            type: actions.FILE_READ_SUCCESS,
            payload: rawData,
          });
        } catch (error) {
          console.error(error);
          dispatch({
            type: actions.FILE_READ_ERROR,
            payload: error,
          });
        }
      },
  };

  return actions;
};
export default actions;
