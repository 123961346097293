const incidentEnumerators = {
  //incidentType: ['Injury', 'Illness', 'NearMiss', 'PropertyDamage', 'Environmental', 'Vehicle', 'Other'],
  initialClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease'], // 'NotCoveredByLaw', 'NotApplicable'],
  finalClassification: ['WorkAccident', 'CommutingAccident', 'OccupationalDisease'], // 'NotCoveredByLaw', 'NotApplicable'],
  severity: ['Mild', 'Severe', 'Fatal'],
  incidentStatus: ['Open', 'Closed'],
  withLostDaysSelector: ['yes', 'no'],
  contractType: ['Unspecified', 'Indefinite', 'FixedTerm', 'Obra o Faena', 'Other'],
  entryType: ['ProcessContinuity', 'NewEntry', 'ReEntry'],
  origin: [
    'Undetermined',
    'Attrition',
    'SameLevelFall',
    'FallFromDifferentLevel',
    'ContactWithElectricalEnergy',
    'ContactWithCuttingMaterial',
    'ContactWithChemicalSubstance',
    'ContactWithHotObject',
    'StruckBy',
    'StruckAgainst',
    'InjuryCausedByAnimals',
    'RepetitiveMotion',
    'Overexertion',
    'AscensoControlado',
    'EscapeLibre',
  ],
  managementBlumar: {
    'Blumar S.A.': ['Gerencia Operaciones', 'Subgerencia Logística'],
    'Pesquera Bahía Caldera S.A.': ['Gerencia Operaciones'],
    'Salmones Blumar Magallanes Spa': ['Gerencia Farming', 'Gerencia Procesamiento Salmones'],
    'Salmones Blumar S.A.': ['Gerencia Farming', 'Gerencia Procesamiento Salmones'],
  },
};

export default incidentEnumerators;
